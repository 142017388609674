export default defineNuxtRouteMiddleware(async (to) => {
    const noNeedChecking = ['/auth/verify-email', '/auth/forgot-password', '/auth/forgot-password/reset'];
    if (noNeedChecking.includes(to.path)) return;

    async function getUser(id: string) {
        const { data, error } = await useFetch(`/api/users/${id}`);

        return { data, error };
    }

    const session = import.meta.server ? useSupabaseSession() : null;
    const store = import.meta.client ? userSessionStore() : null;
    const { session: clientSession } = store ? storeToRefs(store) : { session: null };

    const currentSession = session?.value || clientSession?.value;

    if (!currentSession) {
        if (to.path !== '/auth/signin' && to.path !== '/auth/signup') {
            return navigateTo('/auth/signin');
        }
        return;
    }

    const user = currentSession.user ? await getUser(currentSession.user.id) : null;

    // Conditional Redirects
    if (user?.error.value?.statusCode?.toString().startsWith('5')) {
        return navigateTo('/server-error');
    }
    if (
        to.path !== '/auth/onboarding' &&
        !currentSession.user.user_metadata.organization_id &&
        !currentSession.user.user_metadata.hasBooked
    ) {
        return navigateTo('/auth/onboarding');
    }

    if (
        to.path.startsWith('/auth') &&
        currentSession.user.user_metadata.organization_id &&
        user?.data.value?.status === 'active'
    ) {
        return navigateTo('/dashboard');
    }

    if (to.path !== '/auth/calendar' && currentSession.user.user_metadata.hasBooked == false) {
        return navigateTo('/auth/calendar');
    }

    if (
        to.path !== '/auth/booked' &&
        user?.data.value?.status === 'waiting confirmation' &&
        currentSession.user.user_metadata.hasBooked == true
    ) {
        return navigateTo('/auth/booked');
    }
});
