import revive_payload_client_7B9wXudzRR from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mAPUAdLHHf from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9CDBbmdR9J from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_HvTUzKSkA1 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.12_terser@5_rkgljyfxmh6u6czkfmxedp4c3q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_xN1bvT0pFK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_cHX3QMRoWN from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_hTjtjl6lMG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_1mA5AfeQDV from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.19.0_typescript@5.4.5_vue@3.4.30_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_9htdibUffd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_XAXwyS6TzG from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._75y63usu2bscsn4qfevg6y5kzu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import titles_VZ06Ao59yS from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.1_24jua5bmt7gbuyn4w7spevam3q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_HIvmRRIIAA from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.12_terse_ckfswxohllatxcv3c6laoaiaoy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_mDzUwyjvyU from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.12_terse_ckfswxohllatxcv3c6laoaiaoy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_P3qwEyFoEy from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_magicast@0.3.4_rollup@4.19.0_sortablejs@1.14.0_vite@5.3.4_@types+node@20.14.1_iu5gjlrgznlght7xt6qm7c3zeq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_IVi7z7a11N from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_magicast@0.3.4_rollup@4.19.0_sortablejs@1.14.0_vite@5.3.4_@types+node@20.14.1_iu5gjlrgznlght7xt6qm7c3zeq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_IBxNz50P8N from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_magicast@0.3.4_rollup@4.19.0_sortablejs@1.14.0_vite@5.3.4_@types+node@20.14.1_iu5gjlrgznlght7xt6qm7c3zeq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_1t6fBg7LId from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_MAnHMwT9Re from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import defaults_386s7KZVrE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.1_24jua5bmt7gbuyn4w7spevam3q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_7B9wXudzRR,
  unhead_mAPUAdLHHf,
  router_9CDBbmdR9J,
  _0_siteConfig_HvTUzKSkA1,
  supabase_client_xN1bvT0pFK,
  payload_client_cHX3QMRoWN,
  check_outdated_build_client_hTjtjl6lMG,
  plugin_vue3_1mA5AfeQDV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9htdibUffd,
  sentry_client_XAXwyS6TzG,
  sentry_client_config_o34nk2sJbg,
  titles_VZ06Ao59yS,
  siteConfig_HIvmRRIIAA,
  inferSeoMetaPlugin_mDzUwyjvyU,
  slideovers_P3qwEyFoEy,
  modals_IVi7z7a11N,
  colors_IBxNz50P8N,
  plugin_client_1t6fBg7LId,
  chunk_reload_client_MAnHMwT9Re,
  analytics_client_mtqVw3YRXk,
  posthog_client_fUucxKWhZE,
  defaults_386s7KZVrE
]